import Element from 'element-ui';
import STATUS_CODE from './code';
import $interfaces from './interfaces';

/**
 * 响应消息统一处理函数
 * @param {String} Object.method  http 请求的方法
 * @param {Number} Object.code    响应的业务状态码
 * @param {String} Object.message 响应的业务消息
 * @param {Error}  Object.error   http 响应的物理错误
 */
function messageHandle({ method, code, message }) {
  /**
   * 如果是 get 请求并且 code 正常，则直接返回
   * 因为往往在获取数据的时候并不需要类似"获取成功"这类的提示
   */
  if (method === 'get' && code === STATUS_CODE.OK) {
    return;
  }

  // 像 post、put、delete 这类写入类型的请求，则需要告诉用户是否操作成功
  switch (code) {
    case STATUS_CODE.OK:
      if (message) {
        Element.Message.success(message);
      }
      break;
    case STATUS_CODE.LACK_PARAMS:
      Element.Message.warning(message);
      break;
    case STATUS_CODE.TOKEN_ERR:
    case STATUS_CODE.AUTH_ERR:
    case STATUS_CODE.SERVER_ERR:
      Element.Message.error(message);
      break;
  }
}

/**
 * 检查 get 请求响应头的 content-type 是否包含 application/json，前后端约定通常情况下非 application/json 类型都是文件流
 * @param {String} method http 请求的方法
 * @param {String} conType content-type 值
 * @return {Boolean}
 */
function resStream(method, contentType) {
  return method === 'get' && !contentType.includes('application/json');
}

const toAuthorization = async () => {
  localStorage.clear();

  Element.Loading.service();

  const authorizeURL = await $interfaces.setOrder.list();

  // const currentAppURL = location.origin + process.env.BASE_URL;
  // const callbackURL = authorizeURL + currentAppURL;
  console.log(authorizeURL, 1);
  // location.replace(authorizeURL);
};

export { messageHandle, resStream, toAuthorization };
