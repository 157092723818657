// 嵌套路由层级，将指定层级以下的路由激活样式归属到指定层级。
const depth = 2;

/**
 * menu 菜单激活样式默认只有一级和二级才有，往下的路由层级则没有激活样式(也没有相应的菜单)，此函数将二级菜单以下的路由层级都视为二级菜单，将由二级菜单来做激活的样式归属。
 * @param {Object} to 守卫的 to
 */
export default to => {
  const fullPath = to.fullPath.split('/');
  fullPath.shift();

  if (fullPath.length > depth) {
    const firstLevel = fullPath[0];
    const secondLevel = fullPath[1];
    const activePath = `/${firstLevel}/${secondLevel}`;

    to.meta.menuActive = activePath;
  }
};
