export default ({ path }, next) => {
  if (path === '/403' || path === '/' || path === '/user/edit') {
    return;
  }

  // const userInfo = JSON.parse(localStorage.getItem('user'));

  // if (userInfo.permissions) {
  //   // const menus = userInfo.permissions.flatten_menus;
  //   // const checkAuth = menus.some(menu => fullPath.includes(menu));

  //   // if (!checkAuth) {
  //   //   next('/403');
  //   // }
  // }
  next();
};
