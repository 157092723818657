import axios from 'axios';
import dataModel from '../model';
import STATUS_CODE from '../code';
import { messageHandle, resStream, toAuthorization } from '../utils';

/**
 * 响应拦截器
 */
axios.interceptors.response.use(
  response => {
    const { config, headers, data } = response;
    const { code, message } = data;
    const method = config.method;
    const isStream = resStream(config.method, headers['content-type']);

    // Response Message
    messageHandle({ method, code, message });

    if (isStream) {
      return response.data;
    }
    // 根据 请求方式 更改响应数据结构
    switch (method) {
      case 'post':
      case 'put':
      case 'delete':
        response.data.data = dataModel({ ...data });
        break;
    }
    switch (code) {
      // 如果 token 错误
      case STATUS_CODE.TOKEN_ERR:
        toAuthorization();
        return Promise.reject(response.data.data);
      case STATUS_CODE.REDIRECT:
        return Promise.reject(response);
      default:
        return response.data;
    }
  },
  error => {
    const { redirect, contentpath } = error.response.headers;
    if (error.response.status === STATUS_CODE.REDIRECT) {
      if (redirect === 'REDIRECT') {
        let nextPage = document.createElement('a');
        nextPage.setAttribute('href', contentpath);
        nextPage.click();
        console.log(window.location.href);
        localStorage.setItem('origintpath', window.location.href);
        // window.location.href = contentpath + '&&time=' + ((new Date()).getTime())
      }
    }
    // Response Message
    messageHandle({ error });
    localStorage.clear();
    return Promise.reject(dataModel());
  }
);
