/**
 * 请求拦截器
 */
import axios from 'axios';
// import { offline } from '/app.config';

// const sourceCancel = axios.CancelToken.source();

axios.interceptors.request.use(
  function (config) {
    // if (offline) {
    //   config.data.cancelToken = sourceCancel.token;
    // }
    // console.log(sourceCancel);
    config.headers.accessToken = localStorage.getItem('token');
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    config.headers.requestType = '2';
    return config;
  },
  function (error) {
    // 处理错误请求
    return Promise.reject(error);
  }
);
