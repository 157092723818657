import Vue from 'vue';
import VueRouter from 'vue-router';
import { toAuthorization } from '@/axios/utils';
import clearWindowInterVals from './clearWindowInterVals';
import checkRouteAuth from './checkRouteAuth';
import menuActive from './menuActive';
// import { offline } from '/app.config';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/components/app/app'),
    children: [
      {
        path: '/overview',
        name: 'overview',
        component: () => import('@/views/overview/overview'),
        children: [
          {
            path: 'overview',
            name: 'overview',
            component: () => import('@/views/overview/overview'),
            meta: {
              breadcrumb: ['概况']
            }
          }
        ]
      },
      {
        path: '/work-order',
        name: '事项管理',
        component: () => import('@/views/work-order/work-order'),
        children: [
          {
            path: 'working',
            name: 'working',
            component: () => import('@/views/work-order/working/working'),
            meta: {
              breadcrumb: ['事项管理', '正在进行']
            }
          },
          {
            path: 'working/detail/:id',
            name: 'working_detail',
            component: () => import('@/views/work-order/working/detail'),
            props: true,
            meta: {
              breadcrumb: [
                '事项管理',
                {
                  url: '/work-order/working',
                  title: '正在进行'
                },
                '事项详情'
              ]
            }
          },
          {
            path: 'completed/detail/:id',
            name: 'completed_detail',
            component: () => import('@/views/work-order/completed/detail'),
            props: true,
            meta: {
              breadcrumb: [
                '事项管理',
                {
                  url: '/work-order/completed',
                  title: '已完成'
                },
                '事项详情'
              ]
            }
          },
          {
            path: 'evaluate',
            name: 'evaluate',
            component: () => import('@/views/work-order/evaluate/evaluate'),
            props: true,
            meta: {
              breadcrumb: ['事项管理', '事项评价']
            }
          },
          {
            path: 'evaluate/detail/:id',
            name: 'evaluate_detail',
            component: () => import('@/views/work-order/completed/detail'),
            props: true,
            meta: {
              breadcrumb: [
                '事项管理',
                {
                  url: '/work-order/evaluate',
                  title: '事项评价'
                },
                '事项详情'
              ]
            }
          },
          {
            path: 'completed',
            name: 'completed',
            component: () => import('@/views/work-order/completed/completed'),
            meta: {
              breadcrumb: ['事项管理', '已完成']
            }
          }
        ]
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/user'),
        children: [
          {
            path: 'personal',
            name: 'user_list',
            component: () => import('@/views/user/personal/personal'),
            meta: {
              breadcrumb: ['人员管理', '人员管理列表']
            }
          },
          {
            path: 'personal/detail/:id',
            name: 'personal_detail',
            component: () => import('@/views/user/personal/detail'),
            props: true,
            meta: {
              breadcrumb: [
                '人员管理',
                {
                  url: '/user/personal',
                  title: '人员管理列表'
                },
                '人员详情'
              ]
            }
          }
        ]
      },
      {
        path: '/setting',
        name: 'setting',
        component: () => import('@/views/setting/setting'),
        children: [
          {
            path: 'base',
            name: 'base',
            component: () => import('@/views/setting/base/base'),
            meta: {
              breadcrumb: ['系统设置']
            }
          }
        ]
      }
    ],
    redirect: '/overview'
  },
  {
    path: '/403',
    component: () => import('@/views/403/403.vue')
  }
  // {
  //   path: '/white',
  //   component: () => import('@/views/white/white.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const code = to.query.code;
  const token = localStorage.getItem('token');
  if (code && !token) {
    const getAccess = await import('./getAccess');
    getAccess.default(code, to, next);
  } else if (!code && !token) {
    toAuthorization();
    next(false);
  } else {
    const storageAccess = await import('./storageAccess');
    storageAccess.default(next);
    menuActive(to);
    checkRouteAuth(to, next);
  }
  // const storageAccess = await import('./storageAccess');
  // storageAccess.default(next);
  // menuActive(to);
  // checkRouteAuth(to, next);
  clearWindowInterVals();
});
// if (!offline) {
//   router.beforeEach(async (to, from, next) => {
//     const code = to.query.code;
//     const token = localStorage.getItem('token');
//     if (code && !token) {
//       const getAccess = await import('./getAccess');
//       getAccess.default(code, to, next);
//     } else if (!code && !token) {
//       toAuthorization();
//       next(false);
//     } else {
//       const storageAccess = await import('./storageAccess');
//       storageAccess.default(next);
//       menuActive(to);
//       checkRouteAuth(to, next);
//     }
//     clearWindowInterVals();
//   });
// }

export default router;
