import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import interfaces from '@/axios/interfaces';
import ElementUI from 'element-ui';
import '@/common/js/index';
import 'element-ui/lib/theme-chalk/index.css';
import '@/common/style/index.scss';
import '@/assets/iconfont/iconfont.css';

Vue.prototype.BASE_URL = process.env.VUE_APP_API_BASE_URL;

Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$apis = interfaces;
Vue.prototype.formatDate = function (value) {
  let date = new Date(value).toJSON();
  date = new Date(date);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? `0${MM}` : MM;
  let d = date.getDate();
  d = d < 10 ? `0${d}` : d;
  let h = date.getHours();
  h = h < 10 ? `0${h}` : h;
  let m = date.getMinutes();
  m = m < 10 ? `0${m}` : m;
  let s = date.getSeconds();
  s = s < 10 ? `0${s}` : s;
  return `${y}-${MM}-${d} ${h}:${m}:${s}`;
};
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
