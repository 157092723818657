import { get, post, del, formSubmit, getById } from './helpers';

export default {
  home: {
    getMessage: get('fe/mess_fe_wechat/listPagesCompany'),
    getLatestStatistics: get('fe/fe_order/getLatestStatistics'),
    getTotall: get('fe/fe_order/getOrderClassficationStatistics'),
    getStatus: get('fe/fe_order/getStatusClassficationStatistics'),
    getMatter: get('fe/fe_order/getMatterClassficationStatistics'),
    getLocation: get('fe/fe_order/getLocationClassficationStatistics'),
    getDeptStatistics: get('fe/fe_order_dept_classfication/getDeptStatistics'),
    listCompanyNew: get('fe/fe_order/listCompanyNew'),
    changePassword: post('fe/user/changePassword'),
    logout: post('fe/user/logout')
  },
  oauth: {
    authorize: get('oauth/authorize'),
    accessToken: get('fe/user/callBack'),
    logout: post('oauth/logout'),
    getCurrentUserAllPermissions: get('sysUser/getCurrentUserAllPermissions')
  },
  sysTenant: {
    list: get('sysTenant/list'),
    save: formSubmit('sysTenant/save'),
    get: get('sysTenant/get'),
    update: formSubmit('sysTenant/update', 'put')
  },
  sysUser: {
    list: get('fe/fe_user_orders/listPages'),
    getDetail: getById('fe/fe_user_orders/findDetailById/'),
    listPagesFeOrder: get('fe/fe_user_orders/listPagesFeOrder'),
    listPages: get('fe/fe_user_integration_detail/listPages'),
    add: post('fe/fe_user_orders/saveUser'),
    update: post('fe/fe_user_orders/updateUser')
  },
  workOrder: {
    workingList: get('fe/fe_order/listPagesCompanyHandling'),
    completeList: get('fe/fe_order/listPagesCompanyCompleted'),
    getDetail: getById('fe/fe_order/'),
    getNum: get('fe/fe_order/getCompanyHandlingNumber'),
    getCompleteNum: get('fe/fe_order/getCompanyHandledNumber'),
    edit: post('fe/fe_order_integration'),
    evaluate: get('fe/fe_order_evaluate/listPages'),
    evaluateNum: get('fe/fe_order_evaluate/getEvaluateItemsNumber'),
    getUserList: get('fe/fe_user_orders/queryAllUsers'),
    getAllList: get('fe/fe_order/listCompanyHandling'),
    getComAllList: get('fe/fe_order/listCompanyCompleted')
  },
  setOrder: {
    list: get('fe/fe_order_classfication/list'),
    update: post('fe/fe_order_classfication'),
    del: del('fe/fe_order_classfication')
  },
  setProblem: {
    list: get('fe/fe_matter_classfication/list'),
    update: post('fe/fe_matter_classfication'),
    del: del('fe/fe_matter_classfication')
  },
  setNature: {
    list: get('fe/fe_matter_property/list'),
    update: post('fe/fe_matter_property'),
    del: del('fe/fe_matter_property')
  },
  setDepartment: {
    list: get('fe/fe_order_dept_classfication/list'),
    update: post('fe/fe_order_dept_classfication'),
    del: del('fe/fe_order_dept_classfication')
  },
  setArea: {
    list: get('fe/fe_area_location/list'),
    update: post('fe/fe_area_location'),
    del: del('fe/fe_area_location')
  }
};
