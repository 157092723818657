// import STATUS_CODE from './code';

/**
 * 改造服务端返回的数据结构
 * @param {Number} Object.code 响应的业务状态码
 * @param {Object} Object.data 响应的数据
 * @return {Object} resModel   改造过后的数据
 */
export default function ({ resp_code, data } = {}) {
  // 根据 resModel 的 fail 字段来判断提交是否成功，如果 fail 为 false 则表示本次提交成功
  const resModel = {
    fail: true,
    data: null
  };
  if (resp_code === 0) {
    resModel.fail = false;
    resModel.data = data;
  }

  return resModel;
}
